import {
  Button,
  Dialog,
  DialogContent,
  Grid,
  Paper,
  Typography,
} from "@mui/material";
import HttpService from "../../services/HttpService";
import { useMutation } from "@tanstack/react-query";
import React, { useState } from "react";
import useLabels from "../../labels/useLabels";

export function HowToUseMyCard(props) {
  const [keyClicked, setKeyClicked] = React.useState([]);

  const { getLabel } = useLabels();

  const downloadHtml = useMutation(
    (key) => {
      return HttpService.getAxiosClient()
        .get(
          window.API_URL +
            `/document/` +
            key +
            `/getHTML?subdomain=${window.subdomain}`,
          {},
          { avoidBearer: true }
        )
        .then((response) => ({ response, key }));
    },
    {
      onSuccess: ({ response, key }) => {
        props.setHtmlContent(response.data);
        props.setOpenDialog(true);
        setKeyClicked((prevKeys) =>
          prevKeys.filter((currentKey) => currentKey !== key)
        );
      },
      onError: ({ response, key }) => {
        setKeyClicked((prevKeys) =>
          prevKeys.filter((currentKey) => currentKey !== key)
        );
      },
    }
  );

  const handleDownloadFAQClick = (event, key, type) => {
    setKeyClicked((prevKeys) => [...prevKeys, key]);
    downloadHtml.mutate(key);
    event.preventDefault();
  };

  return (
    <Paper
      sx={{
        margin: "1rem",
        padding: "1rem",
        textAlign: "center",
        maxWidth: "18.5rem",
      }}
    >
      <Typography
        fontWeight="bold"
        textAlign="left"
        paddingBottom="1rem"
        fontSize={14}
      >
        {getLabel("howToUse.title", "How do I use my Virtual Card?")}
      </Typography>
      <Typography textAlign="left" paddingBottom="1rem" fontSize={14}>
        {getLabel(
          "howToUse.message.1",
          "A Virtual Card is a prepaid Mastercard® account, but there is no plastic card. There are two ways to use your card:"
        )}
      </Typography>
      <Typography component="ul">
        <Typography textAlign="left" component="li" fontSize={14}>
          {getLabel(
            "howToUse.li.1",
            '1. You can retrieve your card number on this website by clicking "View Card" above and then use it at online merchants immediately.'
          )}
        </Typography>
        <Typography textAlign="left" component="li" fontSize={14}>
          {getLabel(
            "howToUse.li.2",
            "2. You can use your virtual card in person at a store by adding your card to your mobile phone wallet app - ApplePay, GooglePay or SamsungPay."
          )}
        </Typography>
      </Typography>

      <Typography textAlign="left" paddingTop="1rem" fontSize={14}>
        {getLabel("howToUse.message.faq.please", "Please ")}
        <span
          style={{ fontWeight: "bold", cursor: "pointer" }}
          onClick={(e) => handleDownloadFAQClick(e, "faq")}
        >
          {getLabel("howToUse.message.faq.clickHere", "click here ")}
        </span>{" "}
        {getLabel(
          "howToUse.message.faq.details",
          "to visit our FAQ page for details."
        )}
      </Typography>
    </Paper>
  );
}

export function DialogUI(props) {
  const { getLabel } = useLabels();

  const handleDialogClose = (event, reason) => {
    if (reason !== "backdropClick") props.setOpenDialog(false);
  };

  return (
    <Dialog
      open={props.openDialog}
      onClose={handleDialogClose}
      disableEscapeKeyDown={true}
    >
      <DialogContent>
        <Grid container spacing={1} justifyContent="center">
          <Grid item>
            <div dangerouslySetInnerHTML={{ __html: props.htmlContent }} />
          </Grid>
          <Grid item width="100%">
            <Button
              fullWidth
              variant="contained"
              color="primary"
              onClick={handleDialogClose}
            >
              {getLabel("cardActivation.btnClose", "Close")}
            </Button>
          </Grid>
        </Grid>
      </DialogContent>
    </Dialog>
  );
}

export function CardTokenizationHelp(props) {
  const { getLabel } = useLabels();

  return (
    <Paper
      sx={{
        margin: "1rem",
        padding: "1rem",
        textAlign: "center",
        maxWidth: "18.5rem",
      }}
    >
      <Typography textAlign="left" paddingBottom="1rem" fontSize={14}>
        {getLabel(
          "howToUse.tokenization.message.1",
          "Click the following links for help adding your card to your mobile wallet:"
        )}
      </Typography>
      <Typography component="ul">
        <Typography
          onClick={() =>
            window.open("https://support.apple.com/en-us/HT204506")
          }
          textAlign="left"
          component="li"
          fontSize={14}
          fontWeight={600}
          style={{ cursor: "pointer" }}
        >
          {getLabel("howToUse.tokenization.message.applePay", "Apple Pay Help")}
        </Typography>
        <Typography
          onClick={() =>
            window.open(
              "https://support.google.com/googlepay/answer/10192052?hl=en"
            )
          }
          textAlign="left"
          component="li"
          fontSize={14}
          fontWeight={600}
          style={{ cursor: "pointer" }}
        >
          {getLabel(
            "howToUse.tokenization.message.googlePay",
            "Google Pay Help"
          )}
        </Typography>
        <Typography
          onClick={() =>
            window.open("https://samsung.com/us/support/answer/ANS00045170/")
          }
          textAlign="left"
          component="li"
          fontSize={14}
          fontWeight={600}
          style={{ cursor: "pointer" }}
        >
          {getLabel(
            "howToUse.tokenization.message.samsungPay",
            "Samsung Pay Help"
          )}
        </Typography>
      </Typography>
    </Paper>
  );
}
