import {
  Box,
  Card,
  CardMedia,
  Dialog,
  DialogTitle,
  IconButton,
  LinearProgress,
  Paper,
  Typography,
} from "@mui/material";
import React from "react";
import VisibilityIcon from "@mui/icons-material/Visibility";
import CreditCardOffIcon from "@mui/icons-material/CreditCardOff";
import CardService from "../../services/CardService";
import FeaturesService from "../../services/config/FeaturesService";
import PanView from "./PanView";
import CloseIcon from "@mui/icons-material/Close";
import CardImage from "./CardImage";
import { useTheme } from "@mui/material";

const getDateForCard = (dateString) => {
  if (!dateString) return "";
  const year = parseInt(dateString.substring(2, 4));
  const month = parseInt(dateString.substring(5, 7));
  return `${month.toString().padStart(2, "0")}/${year}`;
};

const CardView = ({ onLoad }) => {
  const [progress, setProgress] = React.useState(0);
  const [showModalPan, setShowModalPan] = React.useState(false);
  const [preactiveImage, setPreactiveImage] = React.useState(null);
  const [suspendImage, setSuspendImage] = React.useState(null);
  const cardService = CardService();
  const cardImage = CardImage();
  const theme = useTheme();
  const defaultColor = "grey";

  React.useEffect(() => {}, []);

  const { isLoading: featuresServiceIsLoading, features } = FeaturesService();

  async function getInitials() {
    const [preactiveImage, suspendImage] = await Promise.all([
      import(`../../images/preactive.${window.subdomain}.svg`),
      import(`../../images/suspend.${window.subdomain}.svg`),
    ]);
    setPreactiveImage(preactiveImage.default);
    setSuspendImage(suspendImage.default);
  }

  React.useEffect(() => {
    getInitials();
  }, []);

  const viewPan = () => {
    setProgress(0);
    setShowModalPan(true);
  };

  const onTimeout = () => {
    setTimeout(() => {
      setShowModalPan(false);
    }, 20000);

    let counter = 0;
    const timer = setInterval(() => {
      setProgress((oldProgress) => {
        if (oldProgress === 100) {
          return 0;
        }
        const diff = 1;
        return Math.min(oldProgress + diff, 100);
      });
      counter += 1;
      if (counter === 100) {
        clearInterval(timer);
      }
    }, 200);

    return () => {
      clearInterval(timer);
    };
  };

  return (
    <>
      {showModalPan && (
        <Dialog
          open={showModalPan}
          onClose={() => setShowModalPan(false)}
          aria-labelledby="modal-modal-title"
          aria-describedby="modal-modal-description"
        >
          <DialogTitle id="modal-modal-title">
            <div
              style={{
                display: "flex",
                flexDirection: "column",
                justifyContent: "center",
                height: "100%",
              }}
            >
              <LinearProgress
                variant="determinate"
                value={progress}
                sx={{ pr: "8" }}
              />
              <IconButton
                style={{ position: "absolute", right: "8px" }}
                onClick={() => setShowModalPan(false)}
              >
                <CloseIcon />
              </IconButton>
            </div>
          </DialogTitle>
          <PanView onTimeout={onTimeout} cardService={cardService} />
        </Dialog>
      )}
      <Card
        elevation={0}
        sx={{ p: 3, backgroundColor: "rgba(255, 255, 255, 0)" }}
      >
        <div
          style={{
            position: "relative",
            minWidth: 200,
            maxWidth: 300,
            margin: "0 auto",
          }}
        >
          <CardMedia
            component="img"
            image={cardImage}
            onLoad={() => onLoad(true)}
          />
          <Paper
            elevation={0}
            sx={{
              position: "absolute",
              top: "85%",
              left: "5%",
              backgroundColor: "transparent",
            }}
          >
            <Typography
              variant="body2"
              sx={{
                fontFamily: "sans-serif",
                color:
                  theme?.palette?.cardText?.[cardService.cardDetail.brand]?.[
                    cardService.cardDetail.shortLabel
                  ] ||
                  theme?.palette?.cardText?.default ||
                  defaultColor,
              }}
            >
              {cardService.cardDetail.completeName}
            </Typography>
          </Paper>
          <Paper
            elevation={0}
            sx={{
              position: "absolute",
              top: "60%",
              left: "5%",
              backgroundColor: "transparent",
            }}
          >
            <Typography
              variant="subtitle1"
              sx={{
                fontFamily: "sans-serif",
                display: "flex",
                alignItems: "center",
                color:
                  theme?.palette?.cardText?.[cardService.cardDetail.brand]?.[
                    cardService.cardDetail.shortLabel
                  ] ||
                  theme?.palette?.cardText?.default ||
                  defaultColor,
              }}
            >
              {`${cardService.cardDetail.pan.substr(
                0,
                4
              )} ${cardService.cardDetail.pan.substr(
                4,
                4
              )} ${cardService.cardDetail.pan.substr(
                8,
                4
              )} ${cardService.cardDetail.pan.substr(12, 4)}`}
              {!featuresServiceIsLoading && features?.VIEW_PAN && (
                <IconButton
                  color="inherit"
                  sx={{ marginLeft: "0.5rem", cursor: "pointer" }}
                  onClick={viewPan}
                >
                  <VisibilityIcon fontSize="small" />
                </IconButton>
              )}
            </Typography>
          </Paper>
          <Paper
            elevation={0}
            sx={{
              position: "absolute",
              top: "73%",
              left: "55%",
              backgroundColor: "transparent",
            }}
          >
            <Typography
              variant="subtitle1"
              sx={{
                fontFamily: "sans-serif",
                color:
                  theme?.palette?.cardText?.[cardService.cardDetail.brand]?.[
                    cardService.cardDetail.shortLabel
                  ] ||
                  theme?.palette?.cardText?.default ||
                  defaultColor,
              }}
            >
              {getDateForCard(cardService.cardDetail.expirationDate)}
            </Typography>
          </Paper>
          {cardService.cardDetail.statusDisplay.toUpperCase() === "PENDING" ? (
            <>
              <Box
                sx={{
                  position: "absolute",
                  top: 0,
                  left: 0,
                  height: "100%",
                  width: "100%",
                  zIndex: 1,
                  backgroundColor: "rgba(255, 255, 255, 0.80)",
                }}
              />
              <Box
                sx={{
                  position: "absolute",
                  top: "50%",
                  left: "50%",
                  transform: "translate(-50%, -50%)",
                  zIndex: 1,
                }}
              >
                <img src={preactiveImage} alt="Pending to activate" />
              </Box>
            </>
          ) : null}
          {cardService.cardDetail.statusDisplay.toUpperCase() ===
          "SUSPENDED" ? (
            <>
              <Box
                sx={{
                  position: "absolute",
                  top: 0,
                  left: 0,
                  height: "100%",
                  width: "100%",
                  zIndex: 1,
                  backgroundColor: "rgba(255, 255, 255, 0.80)",
                }}
              />
              <Box
                sx={{
                  position: "absolute",
                  top: "50%",
                  left: "50%",
                  transform: "translate(-50%, -50%)",
                  zIndex: 1,
                }}
              >
                <img src={suspendImage} alt="Suspended card" />
              </Box>
            </>
          ) : null}
          {cardService.cardDetail.statusDisplay.toUpperCase() === "STOLEN" ? (
            <>
              <Box
                sx={{
                  position: "absolute",
                  top: 0,
                  left: 0,
                  height: "100%",
                  width: "100%",
                  zIndex: 1,
                  backgroundColor: "rgba(255, 255, 255, 0.80)",
                }}
              />
              <Box
                sx={{
                  position: "absolute",
                  top: "50%",
                  left: "50%",
                  transform: "translate(-50%, -50%)",
                  zIndex: 1,
                }}
              >
                <CreditCardOffIcon
                  sx={{ minWidth: "6rem", minHeight: "6rem" }}
                />
                <Typography sx={{ textAlign: "center", fontWeight: "bold" }}>
                  STOLEN
                </Typography>
              </Box>
            </>
          ) : null}
          {cardService.cardDetail.statusDisplay.toUpperCase() === "LOST" ? (
            <>
              <Box
                sx={{
                  position: "absolute",
                  top: 0,
                  left: 0,
                  height: "100%",
                  width: "100%",
                  zIndex: 1,
                  backgroundColor: "rgba(255, 255, 255, 0.80)",
                }}
              />
              <Box
                sx={{
                  position: "absolute",
                  top: "50%",
                  left: "50%",
                  transform: "translate(-50%, -50%)",
                  zIndex: 1,
                }}
              >
                <CreditCardOffIcon
                  sx={{ minWidth: "6rem", minHeight: "6rem" }}
                />
                <Typography sx={{ textAlign: "center", fontWeight: "bold" }}>
                  LOST
                </Typography>
              </Box>
            </>
          ) : null}
        </div>
      </Card>
    </>
  );
};

export default CardView;
