/* Personal information */
import * as React from "react";
import {
  FormControl,
  FormControlLabel,
  Grid,
  Radio,
  RadioGroup,
  TextField,
} from "@mui/material";
import LoadingButton from "../../components/ui/LoadingButton";
import useLabels from "../../labels/useLabels";
import { useMutation } from "@tanstack/react-query";
import HttpService from "../../services/HttpService";
import { DatePicker } from "@mui/x-date-pickers";
import { useFormik } from "formik";
import * as validatorYup from "yup";
import { addYears, parseISO, format } from "date-fns";
import InputMask from "react-input-mask";
import OnboardingConfigService from "../../services/config/OnboardingConfigService";
import OnboardingEmployerAttributeService from "../../services/config/OnboardingEmployerAttributeService";

export default function StepPersonalInformation({ onSave, email, token, bagContent, employerId }) {
  const thisStep = "3";
  const { getLabel } = useLabels();
  
  const showBusinessInfoFields = OnboardingConfigService(employerId ? "onboarding.showEin."+employerId : undefined);
  const onboardingEmployerAttributes = OnboardingEmployerAttributeService(employerId, "PAYCARD_USE_GENERATED_DOB,PAYCARD_USE_GENERATED_PHONE,PAYCARD_USE_GENERATED_SSN").attribute;
  
  const validationSchema = validatorYup.object({
    documentType: validatorYup.string().required("Required field"),
    ssnItin: !bagContent?.ssnItinDisabled &&  validatorYup
      .string()
      .when("documentType", {
        is: (value) => value === "1",
        then: () =>
          validatorYup
            .string()
            .matches(
              /^(?!219099999|078051120)(?!666|000|9\d{2})\d{3}-?\d{2}-?\d{4}$/,
              "Invalid SSN"
            ).required("Required field"),
        otherwise: () =>
          validatorYup
            .string()
            .matches(/^9\d{8}$/, "Invalid ITIN").required("Required field"),
      }),
    firstName: validatorYup
      .string()
      .matches(/^[^\s]*$/, "First name cannot contain spaces")
      .matches(/^[^0-9]*$/, "Cannot contain numbers")
      .matches(/^[a-zA-Z]*$/, "Cannot contain special characters")
      .required("Required field"),
    middleName: validatorYup
      .string()
      .matches(/^[^\s]*$/, "Middle name cannot contain spaces")
      .matches(/^[^0-9]*$/, "Cannot contain numbers")
      .matches(/^[a-zA-Z]*$/, "Cannot contain special characters"),
    lastName: validatorYup
      .string()
      .matches(/^[^\s]*$/, "Last name cannot contain spaces")
      .matches(/^[a-zA-Z]*$/, "Cannot contain special characters")
      .required("Required field"),
    mobilePhone: !bagContent?.mobilePhoneDisabled && validatorYup
      .string()
      .matches(
        /^(\+1|1)?\s?-?\s?(\([2-9][0-8][0-9]\)|[2-9][0-8][0-9])\s?-?\s?[2-9][0-9]{2}\s?-?\s?[0-9]{4}$/,
        "Invalid US phone number"
      )
      .required("Required field"),
    dob: !bagContent?.dobDisabled && validatorYup
      .date()
      .min(
        new Date(new Date().setFullYear(new Date().getFullYear() - 100)),
        "You must be less than 100 years old"
      )
      .max(
        new Date(new Date().setFullYear(new Date().getFullYear() - 16)),
        "You must be at least 16 years old"
      )
      .required("Required field"),
    businessName: validatorYup
      .string()
      .matches(/^[a-zA-Z0-9 ]{1,20}$/, "Invalid business name"),
    ein: validatorYup
      .string()
      .test('EIN validation', 'Invalid EIN', (value) => {
        if (value === undefined || value === "__-_______") {
          return true;
        }
        return /^\d{2}-?\d{7}$/.test(value);})
  });

  const formik = useFormik({
    initialValues: {
      documentType: "1",
      ssnItin: "",
      firstName: "",
      middleName: "",
      lastName: "",
      mobilePhone: "",
      homePhone: "",
      dob: null,
      ein: "",
      businessName: "",
    },
    validationSchema: validationSchema,
    onSubmit: (values) => {
      const transformedValues = {
        email: email,
        token: token,
        step: (Number(thisStep) + 1).toString(), 
        bagContent: { 
          ...values, 
          firstName: values.firstName.trim().toUpperCase(),
          middleName: values.middleName.trim().toUpperCase(),
          lastName: values.lastName.trim().toUpperCase(),
          dob: format(values.dob, 'yyyy-MM-dd'), 
          ssnItin: values.ssnItin.replace(/-/g, '') ,
          ein: values.ein.replace(/-/g, '')
        },
      };

      processStep.mutate({ ...transformedValues });
    },
  });

  const processStep = useMutation(
    (data) => {
      return HttpService.getAxiosClient().post(
        window.API_URL + `/onboarding/saveStep`,
        data,
        { avoidBearer: true }
      );
    },
    {
      onSuccess: (values) => {
        onSave(formik.values.firstName + " " + formik.values.lastName);
      },
      onError: (values) => {},
    }
  );

  React.useEffect(() => {
    if (bagContent !== null) {
      bagContent?.documentType
        ? formik.setFieldValue("documentType", bagContent?.documentType)
        : formik.setFieldValue("documentType", "1");
      bagContent?.ssnItin
        ? formik.setFieldValue("ssnItin", bagContent?.ssnItin)
        : formik.setFieldValue("ssnItin", "");
      bagContent?.firstName
        ? formik.setFieldValue("firstName", bagContent?.firstName)
        : formik.setFieldValue("firstName", "");
      bagContent?.middleName
        ? formik.setFieldValue("middleName", bagContent?.middleName)
        : formik.setFieldValue("middleName", "");
      bagContent?.lastName
        ? formik.setFieldValue("lastName", bagContent?.lastName)
        : formik.setFieldValue("lastName", "");
      bagContent?.mobilePhone
        ? formik.setFieldValue("mobilePhone", bagContent?.mobilePhone)
        : formik.setFieldValue("mobilePhone", "");
      bagContent?.homePhone
        ? formik.setFieldValue("homePhone", bagContent?.homePhone)
        : formik.setFieldValue("homePhone", "");
      bagContent?.dob
        ? formik.setFieldValue("dob", parseISO(bagContent?.dob))
        : formik.setFieldValue("dob", null);
      bagContent?.ein
        ? formik.setFieldValue("ein", !!showBusinessInfoFields.config ? bagContent?.ein : "")
        : formik.setFieldValue("ein", "");
      bagContent?.businessName
        ? formik.setFieldValue("businessName", !!showBusinessInfoFields.config  ? bagContent?.businessName : "")
        : formik.setFieldValue("businessName", "");
    }
  }, [bagContent, showBusinessInfoFields.config]);

  const stringToBoolean = (str) => str === 'true';

  return (
    <>
      <Grid
        container
        component="form"
        noValidate
        autoComplete="off"
        onSubmit={formik.handleSubmit}
      >
        <Grid
          item
          xs={6}
          md={4}
          lg={3}
          p={0.5}
          container
          justifyContent="flex-begin"
        >
          <FormControl >
            <RadioGroup
              sx={{ display: (bagContent?.cardType === "PAYCARD" || bagContent?.cardType === "CORPORATE_EXPENSE") && !!onboardingEmployerAttributes?.PAYCARD_USE_GENERATED_SSN ? "none" : "block" }}
              id="documentType"
              name="documentType"
              value={formik.values.documentType}
              onChange={(event) => formik.handleChange(event)}
              row
            >
              <FormControlLabel disabled={stringToBoolean(bagContent?.ssnItinDisabled)} value="1" control={<Radio />} label="SSN" />
              <FormControlLabel disabled={stringToBoolean(bagContent?.ssnItinDisabled)} value="8" control={<Radio />} label="ITIN" />
            </RadioGroup>
          </FormControl>
        </Grid>
        <Grid item xs={6} md={8} lg={9} p={0.5}>
          <TextField
            sx={{ display: (bagContent?.cardType === "PAYCARD" || bagContent?.cardType === "CORPORATE_EXPENSE") && !!onboardingEmployerAttributes?.PAYCARD_USE_GENERATED_SSN ? "none" : "block" }}
            disabled={stringToBoolean(bagContent?.ssnItinDisabled)}
            required
            size="small"
            label="SSN/ITIN Number"
            id="ssnItin"
            type={"text"}
            value={formik.values.ssnItin}
            onChange={formik.handleChange}
            onBlur={formik.handleBlur}
            helperText={formik.touched.ssnItin && formik.errors.ssnItin}
            error={formik.touched.ssnItin && Boolean(formik.errors.ssnItin)}
            fullWidth
          />
        </Grid>
        <Grid item xs={5} p={0.5}>
          <TextField
            disabled={stringToBoolean(bagContent?.firstNameDisabled)}
            required
            size="small"
            label="First Name"
            id="firstName"
            type={"text"}
            value={formik.values.firstName}
            onChange={event => {
              formik.handleChange(event);
              formik.setFieldValue('firstName', event.target.value.toUpperCase());
            }}
            onBlur={formik.handleBlur}
            helperText={formik.touched.firstName && formik.errors.firstName}
            error={formik.touched.firstName && Boolean(formik.errors.firstName)}
            fullWidth
          />
        </Grid>
        <Grid item xs={2} p={0.5}>
          <TextField
            disabled={stringToBoolean(bagContent?.middleNameDisabled)}
            size="small"
            label="Middle Name"
            id="middleName"
            type={"text"}
            value={formik.values.middleName}
            onChange={event => {
              formik.handleChange(event);
              formik.setFieldValue('middleName', event.target.value.toUpperCase());
            }}
            onBlur={formik.handleBlur}
            helperText={formik.touched.middleName && formik.errors.middleName}
            error={
              formik.touched.middleName && Boolean(formik.errors.middleName)
            }
            fullWidth
          />
        </Grid>
        <Grid item xs={5} p={0.5}>
          <TextField
            disabled={stringToBoolean(bagContent?.lastNameDisabled)}
            required
            size="small"
            label="Last Name"
            id="lastName"
            type={"text"}
            value={formik.values.lastName}
            onChange={event => {
              formik.handleChange(event);
              formik.setFieldValue('lastName', event.target.value.toUpperCase());
            }}
            onBlur={formik.handleBlur}
            helperText={formik.touched.lastName && formik.errors.lastName}
            error={formik.touched.lastName && Boolean(formik.errors.lastName)}
            fullWidth
          />
        </Grid>
        <Grid item xs={4} p={0.5}>
          <InputMask
            mask="(999) 999-9999"
            value={formik.values.mobilePhone}
            onChange={formik.handleChange}
            onBlur={formik.handleBlur}
            disabled={stringToBoolean(bagContent?.mobilePhoneDisabled)}
          >
            {() => (
              <TextField
                disabled={stringToBoolean(bagContent?.mobilePhoneDisabled)}
                required
                sx={{display: (bagContent?.cardType === "PAYCARD" || bagContent?.cardType === "CORPORATE_EXPENSE") && !!onboardingEmployerAttributes?.PAYCARD_USE_GENERATED_PHONE ? "none" : "block"}}
                size="small"
                label="Mobile phone"
                id="mobilePhone"
                type={"text"}
                value={formik.values.mobilePhone}
                helperText={
                  formik.touched.mobilePhone && formik.errors.mobilePhone
                }
                error={
                  formik.touched.mobilePhone &&
                  Boolean(formik.errors.mobilePhone)
                }
                fullWidth
              />
            )}
          </InputMask>
        </Grid>
        <Grid item xs={4} p={0.5}>
          <InputMask
            mask="(999) 999-9999"
            value={formik.values.homePhone}
            onChange={formik.handleChange}
            onBlur={formik.handleBlur}
            disabled={stringToBoolean(bagContent?.homePhoneDisabled)}
          >
            {() => (
              <TextField
                disabled={stringToBoolean(bagContent?.homePhoneDisabled)}
                sx={{display: (bagContent?.cardType === "PAYCARD" || bagContent?.cardType === "CORPORATE_EXPENSE") && !!onboardingEmployerAttributes?.PAYCARD_USE_GENERATED_PHONE ? "none" : "block"}}
                size="small"
                label="Home phone"
                id="homePhone"
                type={"text"}
                value={formik.values.homePhone}
                helperText={formik.touched.homePhone && formik.errors.homePhone}
                error={
                  formik.touched.homePhone && Boolean(formik.errors.homePhone)
                }
                fullWidth
              />
            )}
          </InputMask>
        </Grid>
        <Grid item xs={4} p={0.5}>
          <DatePicker
            disabled={stringToBoolean(bagContent?.dobDisabled)}
            required
            label="Date of birth"
            id="dob"
            sx={{
              display: (bagContent?.cardType === "PAYCARD" || bagContent?.cardType === "CORPORATE_EXPENSE") && !!onboardingEmployerAttributes?.PAYCARD_USE_GENERATED_DOB ? "none" : "block",
              width: "100%",
              "& .MuiInputBase-input": {
                padding: "10px",
              },
              "& .MuiIconButton-root": {
                display: "none",
              },
            }}
            onAccept={(date) => {
              formik.setFieldValue("dob", date);
            }}
            onChange={(date) => {
              formik.setFieldValue("dob", date);
            }}
            // minDate={addYears(new Date(), -100)}
            // maxDate={addYears(new Date(), -18)}
            value={formik.values.dob}
            showToolbar={false}
          />
        </Grid>
        {!!showBusinessInfoFields.config ? 
          <>
            <Grid item xs={4} p={0.5}>
              <InputMask
                mask="99-9999999"
                value={formik.values.ein}
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
              >
                {() => (
                  <TextField
                    size="small"
                    label="EIN"
                    id="ein"
                    type={"text"}
                    value={formik.values.ein}
                    helperText={
                      formik.touched.ein && formik.errors.ein
                    }
                    error={
                      formik.touched.ein &&
                      Boolean(formik.errors.ein)
                    }
                    fullWidth
                  />
                )}
              </InputMask>
            </Grid>
            <Grid item xs={8} p={0.5}>
              <TextField
                required
                size="small"
                label="Business Name"
                id="businessName"
                type={"text"}
                inputProps={{ maxLength: 20 }}
                value={formik.values.businessName}
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                helperText={formik.touched.businessName && formik.errors.businessName}
                error={formik.touched.businessName && Boolean(formik.errors.businessName)}
                fullWidth
              /> 
            </Grid> 
          </>: null}
        <Grid item xs={12} p={0.5}>
          <LoadingButton
            size="small"
            variant="contained"
            color="primary"
            type="submit"
            loading={processStep.isLoading}
          >
            {getLabel("onboarding." + thisStep + ".btnContinue", "Continue")}
          </LoadingButton>
        </Grid>
      </Grid>
    </>
  );
}
