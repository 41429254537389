import { useEffect, useState } from "react";
import CardService from "../../services/CardService";

function CardImage() {
  const [cardImage, setCardImage] = useState(null);
  const cardService = CardService();

  useEffect(() => {
    async function getInitials() {
      try {
        const [cardImage] = await Promise.all([
          import(
            `../../images/cards/card.${window.subdomain}.${cardService.cardDetail.brand}.${cardService.cardDetail.shortLabel}.png`
          ),
        ]);
        setCardImage(cardImage.default);
      } catch (error) {
        try {
          const [cardImage] = await Promise.all([
            import(
              `../../images/cards/card.${window.subdomain}.${cardService.cardDetail.brand}.${cardService.cardDetail.shortLabel}.svg`
            ),
          ]);
          setCardImage(cardImage.default);
        } catch (error) {
          try {
            const [cardImage] = await Promise.all([
              import(
                `../../images/cards/card.${window.subdomain}.${cardService.cardDetail.brand}.svg`
              ),
            ]);
            setCardImage(cardImage.default);
          } catch (error) {
            try {
              const [cardImage] = await Promise.all([
                import(`../../images/cards/card.${window.subdomain}.svg`),
              ]);
              setCardImage(cardImage.default);
            } catch (error) {
              try {
                const [cardImage] = await Promise.all([
                  import(`../../images/cards/card.svg`),
                ]);
                setCardImage(cardImage.default);
              } catch (error) {
                console.error("Error loading card image:", error);
              }
            }
          }
        }
      }
    }

    getInitials();
  }, []);

  return cardImage;
}

export default CardImage;
