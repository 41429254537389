export function getTomorrowsDate() {
  const today = new Date();
  const tomorrow = new Date(today);
  tomorrow.setDate(today.getDate() + 1);

  const day = String(tomorrow.getDate()).padStart(2, "0");
  const month = String(tomorrow.getMonth() + 1).padStart(2, "0"); // Months are zero-based
  const year = tomorrow.getFullYear();

  return `${day}/${month}/${year}`;
}

export function getDateDaysAgo(daysAgo) {
  const today = new Date();
  const pastDate = new Date(today);
  pastDate.setDate(today.getDate() - daysAgo);

  const day = String(pastDate.getDate()).padStart(2, "0");
  const month = String(pastDate.getMonth() + 1).padStart(2, "0"); // Months are zero-based
  const year = pastDate.getFullYear();

  return `${day}/${month}/${year}`;
}
