import { useQuery } from "@tanstack/react-query";
import HttpService from "../HttpService";

async function fetchSubDomainConfig() {
  const response = await HttpService.getAxiosClient().get(
    window.API_URL + `/login/config`
  );
  return response;
}

const SubDomainConfigService = () => {
  const { isLoading, isError, data, error } = useQuery(
    ["config"],
    () => fetchSubDomainConfig(),
    {
      refetchOnWindowFocus: false,
      staleTime: 10000,
    }
  );

  const config = data?.data || null;

  return {
    config,
    isLoading,
    isError,
    error,
  };
};

export default SubDomainConfigService;
