import React from "react";
import ReactDOM from "react-dom/client";
import App from "./App";
import { ThemeProvider } from "@mui/material/styles";
import UserService from "./services/UserService";
import HttpService from "./services/HttpService";
import { QueryClient, QueryClientProvider } from "@tanstack/react-query";
import { Provider } from "react-redux";
import store from "./store/store";
import * as Sentry from "@sentry/react";

import(`./styles/${window.subdomain}`).then((module) => {
  const { getTheme } = module;
  const theme = getTheme();
  const queryClient = new QueryClient();

  Sentry.init({
    // dsn: "https://f275d8fc5ae6a396abb5474e0e683e9a@o4507267052929024.ingest.us.sentry.io/4507267058761728",
    dsn: "https://6d4bf86a44694d32b295a54f5381693f@o1162134.ingest.sentry.io/6248984",
    integrations: [
      Sentry.browserTracingIntegration(),
      Sentry.replayIntegration(),
    ],
    // Performance Monitoring
    tracesSampleRate: 1.0, //  Capture 100% of the transactions
    // Set 'tracePropagationTargets' to control for which URLs distributed tracing should be enabled
    tracePropagationTargets: ["localhost", /^https:\/\/yourserver\.io\/api/],
    // Session Replay
    replaysSessionSampleRate: 0.1, // This sets the sample rate at 10%. You may want to change it to 100% while in development and then sample at a lower rate in production.
    replaysOnErrorSampleRate: 1.0, // If you're not already sampling the entire session, change the sample rate to 100% when sampling sessions where errors occur.
  });

  const renderApp = () =>
    ReactDOM.createRoot(document.getElementById("app")).render(
      <Provider store={store}>
        <QueryClientProvider client={queryClient}>
          <ThemeProvider theme={theme}>
            <App />
          </ThemeProvider>
        </QueryClientProvider>
      </Provider>
    );

  UserService.initKeycloak(renderApp);
  HttpService.configure();
});
